import { Box, Flex, Avatar, Heading } from '@chakra-ui/react';

export const AvatarBox = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));

  return (
    <Flex w="100%" align="center" padding="30px 10px 20px" gap="20px">
      <Avatar
        bg="#fff"
        border={'1px solid #474554'}
        color="#474554"
        name="admin"
        size="md"
      />
      <Box>
        <Heading as="h4" fontSize={'md'} fontWeight={600}>
          {admin && admin.name}
        </Heading>
        <p>storyo 관리자</p>
      </Box>
    </Flex>
  );
};
