import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { StatBox } from 'styles/ComponentStyle';
import {
  Box,
  Heading,
  useToast,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
} from '@chakra-ui/react';
import styled from 'styled-components';
import * as server from 'config/Config';
import { DashBoard } from './DashBoardChart';
import { FcOpenedFolder } from 'react-icons/fc';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';
import { redirect } from 'react-router-dom';

const Home = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const toast = useToast();

  const [userData, setData] = useState({
    signup: '',
    accumulateSum: '',
    currentSum: '',
    accumulateOne: '',
    currentOne: '',
    accumulateThree: '',
    currentThree: '',
    accumulateSix: '',
    currentSix: '',
  });

  const {
    signup,
    accumulateSum,
    currentSum,
    accumulateOne,
    currentOne,
    accumulateThree,
    currentThree,
    accumulateSix,
    currentSix,
  } = userData;

  const fetchData = async () => {
    const config = {
      method: 'get',
      url: `${server.SERVER_URL}/user`,
      headers: { Authorization: `Bearer ${admin.token}` },
    };

    await axios(config)
      .then(response => {
        const data = response.data.data;
        const subscribeAccumulate = data.subscribe.accumulate;
        const subscribeCurrent = data.subscribe.current;

        const ArraryAccValues = Object.values(subscribeAccumulate);
        const ArraryCurValues = Object.values(subscribeCurrent);

        const AccValueSum =
          parseInt(ArraryAccValues[0]) +
          parseInt(ArraryAccValues[1]) +
          parseInt(ArraryAccValues[2]);
        const CurValueSum =
          parseInt(ArraryCurValues[0]) +
          parseInt(ArraryCurValues[1]) +
          parseInt(ArraryCurValues[2]);

        setData({
          ...userData,
          signup: data.signup,
          accumulateSum: AccValueSum,
          currentSum: CurValueSum,
          accumulateOne: ArraryAccValues[0],
          currentOne: ArraryCurValues[0],
          accumulateThree: ArraryAccValues[1],
          currentThree: ArraryCurValues[1],
          accumulateSix: ArraryAccValues[2],
          currentSix: ArraryCurValues[2],
        });
      })
      .catch(error => {
        console.error(error);
        const errorStatus = error.response.status;
        const errorResMessage = error.response.data.message;
        toast({
          position: 'top-right',
          title: 'Fail',
          description: `[${errorStatus}] ${errorResMessage}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const accumulate_data = [
    {
      name: '1개월',
      회원수: parseInt(accumulateOne),
    },
    {
      name: '3개월',
      회원수: parseInt(accumulateThree),
    },
    {
      name: '6개월',
      회원수: parseInt(accumulateSix),
    },
  ];

  const current_data = [
    {
      name: '1개월',
      회원수: parseInt(currentOne),
    },
    {
      name: '3개월',
      회원수: parseInt(currentThree),
    },
    {
      name: '6개월',
      회원수: parseInt(currentSix),
    },
  ];

  useEffect(() => {
    // 토큰 상태를 검증하고 필요한 경우 갱신합니다.
    const init = async () => {
      try {
        await verifyTokenAndRefresh(); // 토큰 검증 및 갱신 함수 호출
        fetchData(); // 토큰이 유효하다면 데이터를 불러오는 함수를 호출합니다.
      } catch (error) {
        // 검증 또는 갱신 중 오류 발생 시 처리 로직
        console.error('토큰 검증 또는 갱신 중 오류 발생:', error);
        toast({
          position: 'top-right',
          title: '인증 오류',
          description: '세션 만료 또는 오류 발생. 다시 로그인해주세요.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리 함수 호출
        // 필요한 경우 로그인 페이지로 리다이렉션
      }
    };

    init();
  }, [toast]);

  return (
    <Box maxW="1400px" m="0 auto">
      <Box p={{ base: '3rem 2rem', lg: '4rem 2rem' }}>
        <Flex
          gap="20px"
          align="center"
          padding={{ base: '30px 20px', sm: '30px' }}
        >
          <FcOpenedFolder size={'3em'} />
          <Heading as="h2" size="xl" color="#43655A">
            DashBoard
          </Heading>
        </Flex>
        <BoardBox p={{ base: '2rem', md: '3rem' }}>
          <Heading as="h3" size="lg" fontWeight="600" mb="40px" color="#43655A">
            Sign_up
          </Heading>
          <Flex gap="15px" direction={{ base: 'column', md: 'row' }}>
            <StatBox>
              <StatLabel>누적 가입회원</StatLabel>
              <StatNumber>{signup.accumulate} 명</StatNumber>
              <StatHelpText>현재까지 가입한 storyo 회원</StatHelpText>
            </StatBox>
            <StatBox>
              <StatLabel>당일 가입회원</StatLabel>
              <StatNumber>{signup.daily} 명</StatNumber>
              <StatHelpText>당일 가입한 storyo 회원</StatHelpText>
            </StatBox>
          </Flex>
        </BoardBox>
        <BoardBox p={{ base: '2rem', md: '3rem' }}>
          <Heading as="h3" size="lg" fontWeight="600" mb="40px" color="#43655A">
            구독(누적)
          </Heading>
          <DashBoard
            data={accumulate_data}
            sum={accumulateSum}
            fillColor={'#43655A'}
            statTitle={'총 누적 구독회원'}
            statText={'구독이 누적된 회원'}
          />
        </BoardBox>

        <BoardBox p={{ base: '2rem', md: '3rem' }}>
          <Heading as="h3" size="lg" fontWeight="600" mb="40px" color="#43655A">
            구독(현재)
          </Heading>
          <DashBoard
            data={current_data}
            sum={currentSum}
            fillColor={'#ACA7CB'}
            statTitle={'총 현재 구독회원'}
            statText={'현재 구독 회원'}
          />
        </BoardBox>
      </Box>
    </Box>
  );
};

export default Home;

const BoardBox = styled(Box)`
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 4px 4px 17px -5px rgb(0 0 0 / 20%);
  margin-top: 30px;
`;
